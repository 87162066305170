export const title = "Customer Expiring Point Report";

export const tableWidth = "w-[58rem]";

export const paramSettings = [
  {
    name: "For Month",
    field: "selectedMonth",
    type: "date",
    format: "yyyy-MM-dd 00:00:00",
    operator: "<=",
    default: new Date(),
    nullable: false,
  },
  {
    name: "BC",
    field: "selectedBC",
    type: "number",
    operator: "=",
    default: null,
    nullable: true,
  },
];

export const generateDefaultReportParams = () => {
  const defaultParam = {};

  for (let param of paramSettings) {
    defaultParam[param.field] = param.default;
  }

  return defaultParam;
};

export const groupSetting = {};

export const columnSettings = [
  {
    name: "NO",
    field: "no",
    type: "number",
    width: "w-[4rem]",
    layout: "col-start-center",
  },
  {
    name: "CODE",
    field: "customer_code",
    type: "string",
    width: "w-[6rem]",
    layout: "col-start-start",
  },
  {
    name: "NAME",
    field: "customer_name",
    type: "string",
    width: "w-[10rem]",
    layout: "col-start-start",
  },
  {
    name: "POINT",
    field: "point_active",
    type: "number",
    width: "w-[6rem]",
    layout: "col-start-end",
  },
  {
    name: "EXPIRED",
    field: "point_near_expired",
    type: "number",
    width: "w-[6rem]",
    layout: "col-start-end",
  },
  {
    name: "DETAIL",
    field: "detail",
    type: "string",
    layout: "col-start-start",
    width: "w-[26rem]",
  },
];
