const ReportTemplateReformatCopyData = (listRawData) => {
  const _list = [];

  for (let rawData of listRawData) {
    const row = {};

    row["bc_name"] = rawData.bc_name;
    row["customer_code"] = rawData.customer_code;
    row["customer_name"] = rawData.customer_name;
    row["point_active"] = rawData.point_active;
    row["point_near_expired"] = rawData.point_near_expired;
    row["detail"] = rawData.detail;

    _list.push(row);
  }
  return _list;
};

export default ReportTemplateReformatCopyData;
