import { columnSettings, tableWidth } from "./report-template-setting";

const settings = {};
for (let columnSetting of columnSettings) {
  settings[columnSetting.field] = columnSetting;
}

const ReportTemplateTableHeader = () => {
  return (
    <tr
      id="report-column-header"
      className={`row-start-start ${tableWidth} text-sm bg-zinc-100 font-semibold text-slate-800 `}
    >
      <td
        className={`${settings["no"].layout} ${settings["no"].width} border-zinc-300 border  px-2 h-full pb-2 pt-3 `}
      >
        {settings["no"].name}
      </td>

      <td
        className={`${settings["customer_code"].layout} ${settings["customer_code"].width} border-zinc-300 border border-l-0 px-2 h-full pb-2 pt-3 `}
      >
        {settings["customer_code"].name}
      </td>

      <td
        className={`${settings["customer_name"].layout} ${settings["customer_name"].width} border-zinc-300 border border-l-0 px-2 h-full pb-2 pt-3 `}
      >
        {settings["customer_name"].name}
      </td>

      <td
        className={`${settings["point_active"].layout} ${settings["point_active"].width} border-zinc-300 border border-l-0 px-2 h-full pb-2 pt-3 `}
      >
        {settings["point_active"].name}
      </td>

      <td
        className={`${settings["point_near_expired"].layout} ${settings["point_near_expired"].width} border-zinc-300 border border-l-0 px-2 h-full pb-2 pt-3 `}
      >
        {settings["point_near_expired"].name}
      </td>

      <td
        className={`${settings["detail"].layout} ${settings["detail"].width} border-zinc-300 border border-l-0 px-2 h-full pb-2 pt-3 `}
      >
        {settings["detail"].name}
      </td>
    </tr>
  );
};

export default ReportTemplateTableHeader;
