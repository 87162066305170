import extractDataFromMap from "../../../utils/ExtractDataFromMap";
import groupCalculation from "./report-template-group-calculation";
import { groupSetting } from "./report-template-setting";

const ReportTemplateReformatData = (listData, page, take) => {
  const resultList = [];
  const groupMap = {};
  const groupNames = [];

  for (let i = 0; i < listData.length; i++) {
    const el = listData[i];

    resultList.push({
      ...el,
      mykey: i + 1,
      // no: (page - 1) * take + i + 1,
      no: i + 1,
      report_type: "detail",
    });
  }

  return { list: resultList, groupNames, groupMap };
};

export default ReportTemplateReformatData;
