import { columnSettings } from "./report-template-setting";
import { format } from "date-fns";

const settings = {};
for (let columnSetting of columnSettings) {
  settings[columnSetting.field] = columnSetting;
}

const ReportTemplateDetail = (props) => {
  const {
    mykey,
    no,
    customer_code,
    customer_name,
    point_active,
    point_near_expired,
    detail,
  } = props;

  return (
    <tr
      key={mykey}
      className="row-start-start w-full text-sm text-slate-700 !items-stretch"
    >
      <td
        className={`${settings["no"].layout} ${settings["no"].width} border-zinc-300 border border-t-0  px-2 pt-2 pb-1 `}
      >
        {no}
      </td>

      <td
        className={`${settings["customer_code"].layout} ${settings["customer_code"].width} border-zinc-300 border border-t-0 border-l-0 px-2 pt-2 pb-1 `}
      >
        {customer_code}
      </td>

      <td
        className={`${settings["customer_name"].layout} ${settings["customer_name"].width} border-zinc-300 border border-t-0 border-l-0 px-2 pt-2 pb-1 `}
      >
        {customer_name}
      </td>

      <td
        className={`${settings["point_active"].layout} ${settings["point_active"].width} border-zinc-300 border border-t-0 border-l-0 px-2 pt-2 pb-1 `}
      >
        {point_active ? point_active.toLocaleString("id-ID") : "-"}
      </td>

      <td
        className={`${settings["point_near_expired"].layout} ${settings["point_near_expired"].width} border-zinc-300 border border-t-0 border-l-0 px-2 pt-2 pb-1 `}
      >
        {point_near_expired ? point_near_expired.toLocaleString("id-ID") : "-"}
      </td>

      <td
        className={`${settings["detail"].layout} ${settings["detail"].width} border-zinc-300 border border-t-0 border-l-0 px-2 pt-2 pb-1 `}
      >
        {detail}
      </td>
    </tr>
  );
};

export default ReportTemplateDetail;
