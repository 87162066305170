import { tableWidth, title } from "./report-template-setting";
import { format } from "date-fns";

const ReportTemplateHeader = (props) => {
  const { selectedMonth, selectedBC } = props.reportParam;

  const _selectedMonth = selectedMonth;
  _selectedMonth.setDate(
    new Date(
      _selectedMonth.getFullYear(),
      _selectedMonth.getMonth() + 1,
      0
    ).getDate()
  );

  return (
    <div className={`col-start-start w-full ${tableWidth}`}>
      <div id="report-title" className="bg-zinc-500 p-3 w-full tracking-wide">
        <h1 className="text-white font-semibold">{title}</h1>
      </div>
      <div
        id="report-first-page"
        className="row-between-start text-sm py-4 w-full px-2"
      >
        <div className="col-start-start gap-1">
          <div className="row-start-center gap-2">
            <span className="w-[4rem] text-right"> Periode: </span>
            <span className="font-semibold">
              {_selectedMonth ? format(_selectedMonth, "dd MMM yyyy") : "-"}
            </span>
          </div>
          <div className="row-start-center gap-2">
            <span className="w-[4rem] text-right"> BC: </span>
            <span className="font-semibold">
              {selectedBC ? selectedBC.name : "-"}
            </span>
          </div>
        </div>
        <div className="col-start-start gap-1">
          <div className="row-start-center gap-2">
            <span className="w-[6rem] text-right"> Tanggal Print: </span>
            <span className="font-semibold">
              {format(new Date(), "dd MMM yyyy  HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTemplateHeader;
