import { columnSettings, tableWidth } from "./report-template-setting";

const settings = {};
for (let columnSetting of columnSettings) {
  settings[columnSetting.field] = columnSetting;
}

const ReportTemplateGroupFooter = (props) => {
  const { mykey, id } = props.group_data;
  // const group_count = props.group_count ?? 0;

  return (
    <tr
      key={`${mykey}-group-footer-${props.group_label}`}
      className={`row-start-start ${tableWidth} text-sm text-slate-700`}
    ></tr>
  );
};

export default ReportTemplateGroupFooter;
