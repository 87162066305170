import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { useContext, useEffect, useRef, useState } from "react";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import UiContext from "../../../store/ui-context";
import { ProgressSpinner } from "primereact/progressspinner";
import { useReactToPrint } from "react-to-print";
import HeaderTemplate from "./report-template-header";
import DetailTemplate from "./report-template-detail";
import TableHeaderTemplate from "./report-template-table-header";
import GroupHeaderTemplate from "./report-template-group-header";
import GroupFooterTemplate from "./report-template-group-footer";
import reformatListData from "./report-template-reformat-data";
import reformatListCopyData from "./report-template-reformat-copy-data";
import {
  tableWidth,
  title,
  generateDefaultReportParams,
  paramSettings,
} from "./report-template-setting";
import { confirmDialog } from "primereact/confirmdialog";
import BCSelectorDialog from "../../../dialogs/selectors/BCSelectorDialog";

const ReportExpiringCustomerPoint = () => {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
  });
  const uiContext = useContext(UiContext);
  const dispatch = useDispatch();

  //   const [summaryOnNewPage, setSummaryOnNewPage] = useState(false);

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest();
  const [reportParam, setReportParam] = useState(generateDefaultReportParams());
  const [paging, setPaging] = useState({
    page: 1,
    take: 1000000,
    filter: "",
    total: 0,
  });
  const [listData, setListData] = useState([]);
  const [listRawData, setListRawData] = useState([]);
  const [groupMap, setGroupMap] = useState({});
  const [groupNames, setGroupNames] = useState([]);

  const [showBCSelector, setShowBCSelector] = useState(false);

  // use effect section

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  // API call section

  const fetchListData = async ({ page, take, filter }) => {
    if (loading) return;
    const _filter = filter ? filter : getFilter();

    const targetDate = reportParam.selectedMonth;
    targetDate.setDate(
      new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0).getDate()
    );

    return requestGet({
      fullUrl: `/api/report`,
      params: {
        prefix: "customer_near_expired_point",
        target_date: format(reportParam.selectedMonth, "yyyy-MM-dd"),
        bc_id: reportParam.selectedBC ? reportParam.selectedBC.id : null,
      },
      onSuccess: ({ pagination, message, data }) => {
        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
          filter: _filter,
        });

        const newData = data;

        const {
          list: _listData,
          groupMap,
          groupNames,
        } = reformatListData(newData, paging.page, paging.take);
        setListData(_listData, page, take);
        setListRawData(newData);
        setGroupMap(groupMap);
        setGroupNames(groupNames);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  // other function section

  const getFilter = () => {
    let filterArray = [];
    for (let setting of paramSettings) {
      let singleFilter = undefined;
      const value = reportParam[setting.name];
      const nullable = setting.nullable ?? false;
      switch (setting.type) {
        case "date":
          if (value || nullable) {
            singleFilter = `${setting.field}:${setting.operator}:${
              value ? format(value, setting.format) : "null"
            }`;
          }
          break;
        default:
          if (value || nullable) {
            singleFilter = `${setting.field}:${setting.operator}:${value}`;
          }
          break;
      }
      filterArray.push(singleFilter);
    }
    filterArray.push("tanggal_selesai:=:Null");
    filterArray.push("data_kendaraan.kendaraan_jenis_id:=:1");

    return filterArray.filter((el) => el).join(";");
  };

  const getCopyClipboardData = (listRaw) => {
    let result = "";

    if (listRaw && listRaw.length > 0) {
      const firstRow = listRaw[0];
      const listOfKeys = Object.keys(firstRow).filter(
        (key) =>
          !key.startsWith("data_") &&
          typeof firstRow[key] !== "object" &&
          !key.includes("id") &&
          !key.includes("_id")
      );

      for (let key of listOfKeys) {
        result += `${key}\t`;
      }
      result += "\n";

      for (let row of listRaw) {
        for (let key of listOfKeys) {
          const data = row[key];
          result += `${data}\t`;
        }
        result += "\n";
      }
    }

    return result;
  };

  // UI handler section

  const handleGenerateReport = () => {
    fetchListData({ page: paging.page, take: paging.take });
  };

  const handleCopyToClipboard = () => {
    const copyText = getCopyClipboardData(reformatListCopyData(listRawData));
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        uiContext.showSuccessMsg("Success", "Data berhasil di-copy");
      })
      .catch(() => {
        uiContext.showErrorMsg("Error", "Gagal meng-copy data");
      });
  };

  const handleOnClickBack = () => {
    const accept = async () => {
      navigate("../..");
    };

    const reject = () => {};

    confirmDialog({
      message: `Menutup bon ini, lanjutkan?`,
      header: "Konfirmasi ",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      acceptLabel: "Konfirmasi",
      rejectLabel: "Tidak",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleClickBCFilter = () => {
    setShowBCSelector(true);
  };

  const handleClickRemoveBCFilter = () => {
    setReportParam({ ...reportParam, selectedBC: null });
  };

  const handleConfirmBCFilter = (e, value) => {
    if (value && value.length > 0) {
      const bc = value[0];
      setReportParam({ ...reportParam, selectedBC: bc });
      setShowBCSelector(false);
    }
  };

  // custom UI section

  return (
    <div className={`col-start-start p-4 gap-4 w-100`}>
      <BCSelectorDialog
        visible={showBCSelector}
        onHide={() => setShowBCSelector(false)}
        onConfirm={handleConfirmBCFilter}
      />

      {/* report header section */}
      <div className="elevated-card-tight-vertical row-between-end !px-4 w-100">
        <div className="row-start-center gap-4">
          {/* filter - from */}
          <div className="col-start-start gap-1">
            <span className="text-sm font-semibold text-slate-500 tracking-wider ">
              For Month:
            </span>
            <Calendar
              showIcon
              className="max-w-[12.5rem]"
              view="month"
              value={reportParam.selectedMonth}
              onChange={(e) =>
                setReportParam({ ...reportParam, selectedMonth: e.value })
              }
              dateFormat="MM yy"
              locale="id"
            />
          </div>
          {/* filter BC */}
          <div className="col-start-start gap-1">
            <span className="text-sm font-semibold text-slate-500 tracking-wider ">
              BC:
            </span>
            <div className="row-start-center gap-1">
              <Button
                className="min-w-[12rem] text-left gap-1 bg-blue-50 !text-base"
                icon="pi pi-search"
                label={
                  reportParam.selectedBC ? reportParam.selectedBC.name : "-"
                }
                outlined
                onClick={handleClickBCFilter}
              />
              <Button
                className="max-w-[2.1rem] max-h-[2.1rem] bg-red-400 border-red-400"
                rounded
                icon="pi pi-times"
                severity="danger"
                onClick={handleClickRemoveBCFilter}
              />
            </div>
          </div>
        </div>
        <div className="row-end-end">
          <Button
            icon="pi pi-refresh"
            label="Generate"
            onClick={() => handleGenerateReport()}
          />
        </div>
      </div>
      {/* report control section */}
      <div className="elevated-card-tight-vertical row-between-center w-full">
        <div className="row-center-center gap-4">
          <Button
            className="max-h-[2rem] min-w-[2rem] bg-red-300 border-red-300"
            icon="pi pi-arrow-left"
            severity="danger"
            onClick={handleOnClickBack}
          />

          {/* <div className="row-center-center gap-2">
            <Checkbox
              checked={summaryOnNewPage}
              onChange={(e) => setSummaryOnNewPage(e.checked)}
            />
            <span className="text-sm text-slate-600">Summary on new page</span>
          </div> */}
        </div>
        <Divider layout="vertical" className="max-h-[0.9rem]" />
        <div className="row-center-center gap-3">
          {/* <CopyToClipboard
            text={getCopyClipboardData(listRawData)}
            onCopy={handleCopyToClipboardSuccess}
          > */}
          <Button
            icon="pi pi-copy"
            className="p-button-outlined"
            label="COPY"
            onClick={() => handleCopyToClipboard()}
          />
          {/* </CopyToClipboard> */}
          <Button
            icon="pi pi-print"
            className="p-button-outlined"
            label="PRINT"
            onClick={() => reactToPrintFn()}
          />
        </div>
      </div>

      {/* report content section  */}

      <div className="col-center-center w-full">
        {loading && (
          <div className="col-start-start bg-white shadow-md p-3 border border-zinc-300 w-[55rem]">
            <ProgressSpinner />
          </div>
        )}
        {!loading && (
          <div
            ref={contentRef}
            className={`printContent col-start-start bg-white shadow-md print:shadow-none p-3 border print:border-0 border-zinc-300 `}
          >
            <HeaderTemplate reportParam={reportParam} />
            <table className={`${tableWidth}`}>
              <thead>
                <TableHeaderTemplate />
              </thead>
              <tbody>
                {listData.map((el) => {
                  if (el.report_type === "group-header")
                    return <GroupHeaderTemplate key={el.mykey} {...el} />;
                  else if (el.report_type === "group-footer")
                    return <GroupFooterTemplate key={el.mykey} {...el} />;
                  else return <DetailTemplate key={el.mykey} {...el} />;
                })}
              </tbody>
            </table>
            <div className="h-[2rem]" />
            {/* {summaryOnNewPage && <div className="page-break" />} */}
            {/* <SummaryTemplate
              listRaw={listRawData}
              list={listData}
              groupNames={groupNames}
              groupMap={groupMap}
            /> */}
            <div className="h-[2rem]" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportExpiringCustomerPoint;
