import { useDispatch, useSelector } from "react-redux";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { useContext, useEffect, useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import { addDays, subDays, format, set, addMonths } from "date-fns";
import EventCalendar from "../../../../components/components/event-calendar/EventCalendar";
import { Sidebar } from "primereact/sidebar";
import ListAppointment from "./ListAppointment";
import { Button } from "primereact/button";
import AppointmentEditorDialog from "./AppointmentEditorDialog";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import UiContext from "../../../../store/ui-context";
import { ConfirmDialog } from "primereact/confirmdialog";
import { animate } from "framer-motion";
import { is } from "date-fns/locale";
import AppointmentMobilePage from "./AppointmentMobilePage";
import AppointmentWebPage from "./AppointmentWebPage";

const isMobile = window.innerWidth < 768;

const AppointmentPage = (props) => {
  if (isMobile) {
    return <AppointmentMobilePage />;
  } else {
    return <AppointmentWebPage />;
  }
};

export default AppointmentPage;
