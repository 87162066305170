import { columnSettings, tableWidth } from "./report-template-setting";

const settings = {};
for (let columnSetting of columnSettings) {
  settings[columnSetting.field] = columnSetting;
}

const ReportTemplateGroupHeader = (props) => {
  const { id, mykey } = props.group_data;

  return (
    <tr
      key={`${mykey}-group-header-${props.group_label}`}
      className={`row-start-start ${tableWidth} text-sm text-slate-700 `}
    ></tr>
  );
};

export default ReportTemplateGroupHeader;
