import { useDispatch, useSelector } from "react-redux";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { useContext, useEffect, useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import { addDays, subDays, format, set, addMonths } from "date-fns";
import EventCalendar from "../../../../components/components/event-calendar/EventCalendar";
import { Sidebar } from "primereact/sidebar";
import ListAppointment from "./ListAppointment";
import { Button } from "primereact/button";
import AppointmentEditorDialog from "./AppointmentEditorDialog";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import UiContext from "../../../../store/ui-context";
import { ConfirmDialog } from "primereact/confirmdialog";
import { animate } from "framer-motion";

const title = "Appointment - Web";

const thisMonthFirstDay = set(new Date(), { date: 1 });

const AppointmentWebPage = (props) => {
  const uiContext = useContext(UiContext);

  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [visibleEditor, setVisibleEditor] = useState(false);
  const [editorTitle, setEditorTitle] = useState("New Appointment");
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(thisMonthFirstDay);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editingAppointmentId, setEditingAppointmentId] = useState(-1);
  const [editingAppointment, setEditingAppointment] = useState(null);

  const { requestPost, requestDelete, requestGetOne, loading, requestGet } =
    useAPIRequest();

  const employee = useSelector((state) => state.auth.employee);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    handleChangeMonth(selectedMonth);
  }, []);

  const handleClickDate = (date) => {
    if (!visibleSideBar) setVisibleSideBar(true);
    setSelectedDate(date);
  };

  const convertScheduleCustomerToAppointment = (list) => {
    return list.map((item) => {
      return {
        date: new Date(item.transaction_date),
        title: item.data_customer
          ? item.data_customer.name
          : "*Not Registered*",
        data: item,
        animate: !item.confirmation_bc_date ? true : false,
        stale: !item.confirmation_bc_date ? true : false,
      };
    });
  };

  const updateViewEvent = (event, remove) => {
    // check if event is in events by comparing id
    // if not found, add to events
    // if found, update the event
    // if remove is true, remove the event

    const index = events.findIndex((item) => item.data.id === event.id);
    const newEvent = {
      date: new Date(event.transaction_date),
      title: event.data_customer
        ? event.data_customer.name
        : "*Not Registered*",
      data: event,
    };

    if (index < 0) {
      setEvents([...events, newEvent]);
    } else {
      if (remove) {
        events.splice(index, 1);
      } else {
        events[index] = newEvent;
      }
      setEvents([...events]);
    }
  };

  // const loadAppointment = async (id) => {
  //   return await requestGetOne({
  //     fullUrl: `api/crm/crmschedulecustomer/one/${id}`,
  //     onSuccess: ({ data }) => {
  //       setEditingAppointment(data);
  //     },
  //     onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
  //   });
  // };

  const handleChangeMonth = async (date) => {
    setEvents([]);
    setSelectedMonth(date);

    // get first date of month from date
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDayOfMonth.setHours(0, 0, 0, 0);
    const firstDayOfMonthString = format(
      firstDayOfMonth,
      "yyyy-MM-dd HH:mm:ss"
    );

    // to next month
    const lastDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1 + 1,
      0
    );
    lastDayOfMonth.setHours(23, 59, 59, 999);
    const lastDayOfMonthString = format(lastDayOfMonth, "yyyy-MM-dd HH:mm:ss");

    setSelectedMonth(firstDayOfMonth);

    return await requestGet({
      fullUrl: "api/crm/crmschedulecustomer/data",
      params: {
        take: 10000,
        page: 1,
        order: "transaction_date",
        order_method: "ASC",
        filter: `transaction_date:>=:${firstDayOfMonthString};transaction_date:<=:${lastDayOfMonthString}`,
        with_customer: 1,
        with_bc: 1,
        with_therapist_1: 1,
        with_therapist_2: 1,
        with_therapist_3: 1,
        with_nurse_1: 1,
        with_nurse_2: 1,
        with_nurse_3: 1,
        with_doctor: 1,
        with_products: 1,
        with_crm_options: 1,
      },
      onSuccess: ({ data }) => {
        const newData = convertScheduleCustomerToAppointment(data);
        setEvents(newData);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handleEditEvent = (event) => {
    setEditorTitle("Edit Appointment");
    setVisibleEditor(true);
    setEditingAppointmentId(event.data.id);
    setEditingAppointment(event.data);
  };

  const handleRefreshMonth = async () => {
    handleChangeMonth(selectedDate);
  };

  const handleRemoveAppointment = async (dataIn) => {
    return await requestDelete({
      fullUrl: `api/crm/crmschedulecustomer/delete`,
      ids: [dataIn.id],
      onSuccess: ({ data }) => {
        uiContext.showSuccessMsg("Success", "Appointment removed successfully");
        setVisibleEditor(false);
        updateViewEvent(dataIn, true);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handleAddEvent = () => {
    setEditorTitle("New Appointment");
    setEditingAppointmentId(0);
    setEditingAppointment(null);
    setVisibleEditor(true);
  };

  const _generateAppointmentBody = (data) => {
    let newData = true;
    if (editingAppointmentId > 0) {
      newData = false;
    }

    const body = {};
    if (newData) {
    } else {
      if (data.id) body["id"] = data.id;
    }

    if (data.data_crm_options) {
      const _data_crm_options = data.data_crm_options.map((item) => {
        const newItem = { ...item };
        if (newItem.id <= 0) {
          delete newItem.id;
        }
        return newItem;
      });
      body["data_crm_options"] = _data_crm_options ?? [];
    }

    if (data.data_products) {
      const _data_products = data.data_products.map((item) => {
        const newItem = { ...item };
        if (newItem.id <= 0) {
          delete newItem.id;
        }
        if (newItem.data_product) delete newItem.data_product;
        return newItem;
      });
      body["data_products"] = _data_products ?? [];
    }

    body["branch_id"] = 1;
    body["doctor_note"] = data.doctor_note;
    body["created_by_id"] = data.created_by_id ?? user.id;
    body["confirmation_bc_date"] = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    if (data.bc_id) body["bc_id"] = data.bc_id;
    if (data.customer_id) body["customer_id"] = data.customer_id;
    if (data.transaction_date) body["transaction_date"] = data.transaction_date;
    if (data.description) body["description"] = data.description;
    if (data.doctor_id) body["doctor_id"] = data.doctor_id;
    if (data.nurse_1_id) body["nurse_1_id"] = data.nurse_1_id;
    if (data.nurse_2_id) body["nurse_2_id"] = data.nurse_2_id;
    if (data.nurse_3_id) body["nurse_3_id"] = data.nurse_3_id;
    if (data.therapist_1_id) body["therapist_1_id"] = data.therapist_1_id;
    if (data.therapist_2_id) body["therapist_2_id"] = data.therapist_2_id;
    if (data.therapist_3_id) body["therapist_3_id"] = data.therapist_3_id;
    return body;
  };

  const handleSaveEditor = async (data, done) => {
    // fill body
    let body = _generateAppointmentBody(data);

    if (done) {
      body["done_date"] = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    }

    // validate data
    // if (!body.customer_id) {
    //   uiContext.showErrorMsg("Error", "Customer is required");
    //   return;
    // }
    if (!body.bc_id) {
      uiContext.showErrorMsg("Error", "BC is required");
      return;
    }
    if (!body.transaction_date) {
      uiContext.showErrorMsg("Error", "Appointment Time is required");
      return;
    }

    // save data
    return await requestPost({
      fullUrl: "api/crm/crmschedulecustomer/save",
      body: body,
      onSuccess: ({ data }) => {
        uiContext.showSuccessMsg("Success", "Appointment saved successfully");
        setVisibleEditor(false);
        updateViewEvent(data);
        // handleChangeMonth(selectedMonth);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const getEventsByDate = (date) => {
    return events.filter(
      (event) => format(event.date, "yyyy-MM-dd") === format(date, "yyyy-MM-dd")
    );
  };

  return (
    <div className={`col-start-start px-4 gap-4 w-100`}>
      <ConfirmDialog />
      <AppointmentEditorDialog
        id={editingAppointmentId}
        data={editingAppointment}
        loading={loading}
        visible={visibleEditor}
        title={editorTitle}
        date={selectedDate}
        onHide={() => setVisibleEditor(false)}
        onSave={(data) => handleSaveEditor(data)}
        onRemove={(data) => handleRemoveAppointment(data)}
        onDone={(data) => handleSaveEditor(data, true)}
      />
      <Sidebar
        visible={visibleSideBar}
        position="right"
        onHide={() => setVisibleSideBar(false)}
        className="relative"
      >
        <div className="col-start-start gap-2 pt-1">
          <div className="row-start-center gap-1">
            <Button
              className="max-w-[2rem] max-h-[2rem]"
              icon="pi pi-refresh"
              rounded
              text
              onClick={handleRefreshMonth}
            />
            <span className="font-bold">
              {format(selectedDate, "dd MMMM yyyy").toUpperCase()}
            </span>
          </div>
          <ListAppointment
            events={getEventsByDate(selectedDate)}
            onClick={handleEditEvent}
          />
          <div className="h-[5rem]" />
          <div className="col-center-center gap-2 right-0 left-0 bottom-4 absolute">
            <Button
              icon="pi pi-plus"
              rounded
              raised
              label="New Appointment"
              onClick={handleAddEvent}
            />
          </div>
        </div>
      </Sidebar>
      <div></div>
      <div className="elevated-card-tight-vertical row-start-center w-full gap-3">
        <EventCalendar
          loading={loading}
          events={events}
          selectedMonth={selectedMonth}
          onClick={handleClickDate}
          onChangeMonth={handleChangeMonth}
        />
      </div>
      <div className="elevated-card-tight-vertical row-start-center w-full gap-3">
        {/* next month appointment */}
        <EventCalendar
          loading={loading}
          events={events}
          selectedMonth={addMonths(selectedMonth, 1)}
          onClick={handleClickDate}
          title="NEXT MONTH"
          // onChangeMonth={handleChangeMonth}
        />
      </div>
      <div></div>
    </div>
  );
};

export default AppointmentWebPage;
